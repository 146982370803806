import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const Index = () => {

  const yearsOfExperience = new Date().getFullYear() - 1976;

  const sliderSettings = {
    infinite: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <Layout>
      <SEO 
        title="Glendale Builders LLC - Construction and Renovation"
        description="Glendale Builders LLC is a home construction and renovation company based in the Chicago suburbs. With our expertise, you'll be in your dream home in no time."
        image=""
      />
      <section className="main-homepage" aria-label="Homepage">
        <section className="main-hero" aria-label="Hero Image Slideshow">
          <div className="main-hero__background">
            <div className="main-hero__slider">
              <Slider {...sliderSettings}>
                <div className="main-hero__slide">
                  <div className="main-hero__wrapper">
                    <StaticImage src="../images/kitchens/Northfield-2.jpg" className="main-hero__image" alt="" layout="fullWidth" loading="eager" />
                  </div>
                </div>
                <div className="main-hero__slide">
                  <div className="main-hero__wrapper">
                    <StaticImage src="../images/exteriors/714 Glendale.jpg" className="main-hero__image" alt="" layout="fullWidth" loading="eager" />
                  </div>
                </div>
                <div className="main-hero__slide">
                  <div className="main-hero__wrapper">
                    <StaticImage src="../images/interiors/Northfield-3.jpg" className="main-hero__image" alt="" layout="fullWidth" loading="eager" />
                  </div>
                </div>
                <div className="main-hero__slide">
                  <div className="main-hero__wrapper">
                    <StaticImage src="../images/interiors/Stairs.jpg" className="main-hero__image" alt="" layout="fullWidth" loading="eager" />
                  </div>
                </div>
              </Slider>
            </div>
            <div className="main-hero__content">
              <h2 className="main-hero__title">
                Make Your Dream Home a Reality
              </h2>
              <Link to="/gallery" className="main-hero__link">
                Explore Homes Now
              </Link>
            </div>
          </div>
        </section>
        <section className="main-introduction" aria-label="Introduction to Glendale Builders">
          <div className="main-introduction__container">
            <h2 className="main-introduction__title">
              Welcome to Glendale Builders
            </h2>
            <div className="main-introduction__wrapper">
              <div className="main-introduction__content">
                <h3 className="main-introduction__subtitle">
                  We Specialize in Everything You Need For Your Dream Home
                </h3>
                <ul className="main-introduction__list">
                  <li className="main-introduction__item">
                    Construction
                  </li>
                  <li className="main-introduction__item">
                    Renovation
                  </li>
                  <li className="main-introduction__item">
                    Kitchens and Baths
                  </li>
                  <li className="main-introduction__item">
                    Cabinets and Counter Tops
                  </li>
                  <li className="main-introduction__item">
                    Flooring, Driveways, and Patios
                  </li>
                  <li className="main-introduction__item">
                    And Anything You Could Ever Want
                  </li>
                </ul>
              </div>
              <div className="main-introduction__asset">
                <StaticImage src="../images/interiors/Northfield-24.jpg" className="main-introduction__image" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="main-designs" aria-label="Designs You Will Like">
          <div className="main-designs__container">
            <div className="main-designs__content">
              <h2 className="main-designs__title">
                Take a Look Around. We Have Plenty of Styles You'll Like.
              </h2>
              <div className="main-designs__asset">
                <StaticImage src="../images/bathrooms/Northfield-26.jpg" className="main-designs__image" alt="" />
              </div>
              <p className="main-designs__paragraph">
                If you see something interesting or want to talk to us about your own ideas,
                get in touch with us and see even more beautiful homes and designs like these created by experts.
              </p>
            </div>
          </div>
        </section>
        <section className="main-professionals" aria-label="Glendale Builders Are Professionals">
          <div className="main-professionals__container">
            <div className="main-professionals__wrapper">
              <h2 className="main-professionals__title">
                The Real Estate Industry Can Be Incredibly <em className="main-professionals__stress">Stressful</em>.
              </h2>
              <p className="main-professionals__paragraph">
                With Over { yearsOfExperience } Years of Experience, You Can Rest Easy 
                Knowing You and Your <strong className="main-professionals__family">Family</strong> are in the right hands.
              </p>
            </div>
            <p className="main-professionals__goal">
              At Glendale Builders, you aren't just another client, we're partners with a common goal.
              To achieve your real estate ambitions. Become our next partner today and see why we are
              the best of the best.
            </p>
            <Link to="/contact" className="main-professionals__link">
              Become Our Partner Today
            </Link>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default Index;